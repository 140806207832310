import anime from 'animejs/lib/anime.es.js';

export function headerFunc() {
  console.log('ヘッダーで使うJS');
}

export function headerScrFunc() {
  console.log('スクロールで呼ばれます。');
}

let spNavBtn, slideNavAnimeOpen, Scrollpos;
export function globalNavigationFunc() {
  spNavBtn = $('#spNavBtn');
  //閉じる
  function navClose() {
    anime({
      targets: '#globalNavigations',
      translateY: {
        value: ['0', '-100%'],
        duration: 800,
        easing: 'easeInQuint',
      },
      direction: 'normal',
      complete: function () {
        $('#globalNavigations').removeAttr('style').find('ul li').removeAttr('style');
      }
    });
  }
  //開ける
  function navOpen() {
    slideNavAnimeOpen = anime({
      targets: '#globalNavigations',
      translateY: {
        value: ['-100%', '0'], // []で開始値と終了値を指定できます
        duration: 600, // 0.8秒間アニメーションする
        easing: 'easeInQuint',
      },
      direction: 'normal',
      complete: function () {
        anime({
          targets: '#globalNavigations ul li.globalNavCon',
          //delay:300,
          opacity: {
            value: [0, 1],
            duration: 1000,
            easing: 'easeOutQuart',
          },
          translateY: {
            value: ['3vh', '0'],
            duration: 1000,
            easing: 'easeOutQuart',
          },
          direction: 'normal',
          delay: function (el, i, l) {
            return i * 100;
          }
        });
      },
    });
  }

  spNavBtn.on('click', function () {
    $(this).stop().toggleClass('open');
    if ($(this).hasClass('open')) {
      navOpen();
      Scrollpos = $(window).scrollTop();
      // $('body').addClass('fixedBody').css({
      //   'top': -Scrollpos
      // });
    } else {
      navClose();
      // $('body').removeClass('fixedBody').css({
      //   'top': 0
      // });
      //window.scrollTo(0, Scrollpos);
    }
  });

  let spNavBtnLinks = $('#navMenu .globalNavCon a');
  let setTimer = false;

  function spNavBtnLinksFunction() {
    spNavBtnLinks.on('click touchstart touchmove touchend', function () {
      if (spNavBtn.hasClass('open')) {
        spNavBtn.removeClass('open');
        navClose();
        // $('body').removeClass('fixedBody').css({
        //   'top': 0
        // });
        //window.scrollTo(0, Scrollpos);
      }
    });
  }

  spNavBtnLinksFunction();

  $(window).on('resize', function(){
    var winW = $(window).innerWidth;
    var devW = 767;

    if (setTimer !== false) {
      clearTimeout(setTimer);
    }
    setTimer = setTimeout(() => {
      if (winW <= devW) {
        spNavBtnLinksFunction();
      }
    }, 300);
  });
}