// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, scrTop02, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop02 = $('#scrPageTop02');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
  scrTop02.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight,position;
  let resizecheck = $('#responsibleCheck').width();

  window.addEventListener('resize', () => {
    resizecheck = $('#responsibleCheck').width();
  }, false);

  $('a[href^="#"]:not(.notScroll)').each(function () {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];
      $(this).on('click touchstart touchmove touchend', function () {
        headerHeight = $('#header').outerHeight(true);
        if (resizecheck == '2') {
          position = $('#' + id).offset().top - headerHeight;
          $('body,html').animate({
            scrollTop: position
          }, 1000, 'swing');
        }else{
          position = $('#' + id).offset().top;
          $('body,html').animate({
            scrollTop: position
          }, 1000, 'swing');
        }
        return false;
      });
    }
  });
}