//トップページ　キービジュアルスライダー
import 'slick-carousel';
import 'jquery-scrollify';

const indexTop = $('#indexTop').length;

export function idxFunc() {
  if (indexTop) {
    // const slideWrapper = $(".keyVisualSlider"),
    //   slideItem = $("#topSlider .item"),
    //   video = document.getElementsByTagName('video');

    // // When the slide is changing
    // function changeSlide(slick, control) {
    //   var currentSlide, slideType, startTime, player, video;

    //   currentSlide = slick.find(".slick-current .item");
    //   slideType = currentSlide.attr("class").split(" ")[1];
    //   player = currentSlide.find("iframe").get(0);
    //   startTime = currentSlide.data("video-start");

    //   if (slideType === "video") {
    //     video = currentSlide.children("video").get(0);
    //     if (video != null) {
    //       if (control === "play") {
    //         video.play();
    //       } else {
    //         video.pause();
    //       }
    //     }
    //   }
    // }

    // slideWrapper.on("init", function (slick) {
    //   slick = $(slick.currentTarget);
    //   setTimeout(function () {
    //     changeSlide(slick, "play");
    //   }, 1000);
    // });
    // slideWrapper.on("beforeChange", function (event, slick) {
    //   slick = $(slick.$slider);
    //   changeSlide(slick, "pause");
    // });
    // slideWrapper.on("afterChange", function (event, slick) {
    //   slick = $(slick.$slider);
    //   changeSlide(slick, "play");
    // });

    // if (slideItem.length > 1) {
    //   slideWrapper.slick({
    //     autoplay: true,
    //     fade: true,
    //     autoplaySpeed: 6000,
    //     speed: 600,
    //     arrows: true,
    //     dots: true,
    //     dotsClass: 'slideBanner',
    //     cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
    //   });
    //   for (let i = 0; i < video.length; i++) {
    //     video[i].pause();
    //     video[i].addEventListener('ended', function () {
    //       slideWrapper.slick('slickNext');
    //     });
    //   }
    // } else {
    //   slideWrapper.slick({
    //     autoplay: false,
    //     fade: true,
    //     lazyLoad: "progressive",
    //     arrows: false,
    //     dots: false,
    //     dotsClass: 'slideBanner',
    //     cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
    //   });
    //   if (video.length == 1) {
    //     video[0].play();
    //     video[0].addEventListener('ended', function () {
    //       video[0].play();
    //     });
    //   }
    // }

    // function playPauseVideo(control) {
    //   let currentSlide, slideType, video;

    //   currentSlide = slideWrapper.find(".slick-current .item");
    //   slideType = currentSlide.attr("class").split(" ")[1];

    //   if (slideType === "video") {
    //     video = currentSlide.children("video").get(0);
    //     if (video != null) {
    //       if (control === "play") {
    //         video.play();
    //         slideWrapper.slick('slickPause');
    //       } else {
    //         video.pause();
    //         slideWrapper.slick('slickPlay');
    //       }
    //     }
    //   } else if (slideType === "image") {
    //     slideWrapper.slick('slickPlay');
    //   }
    // }

    // slideWrapper.on("beforeChange", function (event, slick) {
    //   playPauseVideo("pause");
    // });
    // slideWrapper.on("afterChange", function (event, slick) {
    //   playPauseVideo("play");
    // });

    let toggleBtn = $('.toggleBtn');

    toggleBtn.on('click', function () {
      if ($(this).next().not(':animated').length >= 1) {
        $(this).stop().toggleClass('open');
        $(this).stop().next('.toggleCon').slideToggle();
        $('.fadeInClickToggleCon').addClass("active");
      }
    });

    $('.langChangeCon').find('li').on('click', function () {
      console.log('aaa');
      toggleLangBtn.removeClass('open');
      $(this).parent('.langChangeCon').slideUp();
    });

    let toggleLangBtn = $('.langChange');

    toggleLangBtn.on('click', function () {
      if ($(this).next().not(':animated').length >= 1) {
        $(this).stop().toggleClass('open');
        $(this).stop().next('.langChangeCon').slideToggle();
      }
    });

    var messageBoxFlag = false;
    var messageBox = $(".messageBox");
    var quoteIndex = -1;
    let scrollHeight = $('.keyVisual').height() / 2;

    function showNextMessage() {
      ++quoteIndex;
      messageBox.eq(quoteIndex % messageBox.length)
        .fadeIn(3000)
        .delay(4000)
        .fadeOut(3000, showNextMessage);
    }

    $(window).on('load scroll', function () {
      if ($(window).scrollTop() >= scrollHeight) { // トップから100pxスクロールしたらスライドイン
        if (!messageBoxFlag) {
          messageBoxFlag = true;
          showNextMessage();
        }
      }
    });
  }
}

export function movieSwitchFunc() {
  var defaultQueryList = {
    mobile: matchMedia("(max-width: 767px"),
    pc: matchMedia("(min-width: 768px)")
  }

  function routingByBreakpoint(sourceList) {
    for (var i = 0, l = sourceList.length; i < l; i++) {
      var source = sourceList[i];

      if (!(source.dataset.query && defaultQueryList[source.dataset.query] && source.dataset.src)) continue;
      if (!(defaultQueryList[source.dataset.query].matches)) continue;

      var newSource = document.createElement("source");
      newSource.src = source.dataset.src;

      source.parentElement.appendChild(newSource);
    }
  }

  var elemList = document.getElementsByClassName("slide-video");

  for (var i = 0, l = elemList.length; i < l; i++) {
    var sourceList = elemList[i].getElementsByTagName("source");
    routingByBreakpoint(sourceList);
  }
}

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
let fixdScrollpos;
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}

/** スクロール解除 **/
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function modalChart() {
  $('.modalShow').on('click', function () {
    $('.modalChart').addClass('isShow');
  });
  $('.modalClose, .modalChartCon a').on('click', function () {
    $('.modalChart').removeClass('isShow');
  });
}

export function modalQr() {
  $('.modalQrShow').on('click', function () {
    no_scroll();
    $('.modalQr').addClass('isShow');
  });
  $('.modalQrClose').on('click', function () {
    return_scroll();
    $('.modalQr').removeClass('isShow');
  });
}

export function modalMovieFunc() {
  var modalVideoElem = document.getElementById('video01');
  var modalVideoElem02 = document.getElementById('video02');

  $("#movie01").on('click', function () {
    no_scroll();
    $("#modalMovie01").fadeIn();
  });

  $(".modalBack").on('click', function () {
    return_scroll();
    $(".overlay").fadeOut();
    modalVideoElem.pause();
    modalVideoElem02.pause();
  });

  $('.modalMovieClose').on('click', function () {
    return_scroll();
    $(".overlay").fadeOut();
    modalVideoElem.pause();
    modalVideoElem02.pause();
  });

  function VideoControl() {}
  let videoControl = new VideoControl();
  videoControl.movie_list = [];

  function flagSwitch(id) {

    var parent = videoControl.movie_list[id].address,
      target = videoControl.movie_list[id].address.querySelector("video");

    if (!videoControl.movie_list[id].active) {
      parent.classList.add("js-movie_active");
      target.play();
      videoControl.movie_list[id].active = true;
    } else {
      parent.classList.remove("js-movie_active");
      target.pause();
      videoControl.movie_list[id].active = false;
    }

  }

  var myClass = document.getElementsByClassName("jsMovieButton");
  for (var i = 0; i < myClass.length; i++) {
    var parent = myClass[i].closest('.jsMovie');
    var video = parent.querySelector('.jsMovieTarget');
    var statement = {
      active: false,
      address: parent
    };
    parent.dataset.vide_no = i;
    videoControl.movie_list.push(statement);

    myClass[i].addEventListener('click', function () {
      var id = this.closest('.jsMovie').dataset.vide_no;
      flagSwitch(id);
    });

    video.addEventListener('ended', function () {
      var id = this.closest('.jsMovie').dataset.vide_no;
      flagSwitch(id);
    });

  }
}

export function fadeInFunc() {
  $(".fadeIn").each(function () {
    var hit = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var wHeight = $(window).height();

    if (scroll > hit - wHeight + wHeight / 200) {
      $(this).addClass("active");
    }
  });

  $(".fadeInToggleCon").each(function () {
    if ($('.toggleBtn').hasClass('open')) {
      var hit = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var wHeight = $(window).height();

      if (scroll > hit - wHeight + wHeight / 100) {
        $(this).addClass("active");
      }
    }
  });

  $(".shop").each(function () {
    var hit = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var wHeight = $(window).height();

    if (scroll > hit - wHeight + wHeight / 50) {
      $(".pageTopFixed").addClass("hidden");
    } else {
      $(".pageTopFixed").removeClass("hidden");
    }
  });

  const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
  let conceptMainImgAnimationFlag01 = false;

  $('.conceptMainImg').each(function () {
    if($(this).hasClass("active")){
      if (!conceptMainImgAnimationFlag01) {
        conceptMainImgAnimationFlag01 = true;

        wait(600)
          .then(() => {
              $('.conceptMainImgPart01').addClass('isShow');
              return wait(400)
          })
          .then(() => {
              $('.conceptMainImgPart02').addClass('isShow');
              return wait(400)
          })
          .then(() => {
              $('.conceptMainImgPart03').addClass('isShow');
              return wait(600)
          })
      }
    }
  });

  // $(".message").each(function () {
  //   let scrollHeight = $('.keyVisual').height() - 100;

  //   if ($(window).scrollTop() >= scrollHeight) {
  //     setInterval(function () {
  //       $(".message .messageBox:first-of-type").fadeOut(2000).delay(8000).fadeIn(2000).delay(8000);
  //       $(".message .messageBox:nth-of-type(2)").fadeIn(2000).delay(8000).fadeOut(2000).delay(8000);
  //     }, 4000);
  //   }
  // });

  // function pcMessageFunc() {
  //   $(".message").each(function () {
  //     let scrollValue = $(window).scrollTop();
  //     var bgPosition = scrollValue / 18;
  //     let scrollHeight = $('.keyVisual').height() + 50;

  //     if ($(window).scrollTop() >= scrollHeight) {
  //       $(this).addClass('show');
  //     } else {
  //       $(this).removeClass('show');
  //     }

  //     if ($(this).hasClass("show")) {
  //       $(this).find(".messageBox").css('transform', 'translate(-50%, -' + bgPosition + '%)');
  //     } else {
  //       $(this).find(".messageBox").css('transform', 'translate(-50%, 0)');
  //     }
  //   });
  // };

  // function spMessageFunc() {
  //   $(".message").each(function () {
  //     let scrollHeight = $('.keyVisual').height() + 50;
  //     var bgPosition = scrollHeight / 10;

  //     if ($(window).scrollTop() >= scrollHeight) {
  //       $(this).addClass('show');
  //     } else {
  //       $(this).removeClass('show');
  //     }

  //     if ($(this).hasClass("show")) {
  //       $(this).find(".messageBox").css('transform', 'translate(-50%, -' + bgPosition + '%)');
  //     } else {
  //       $(this).find(".messageBox").css('transform', 'translate(-50%, 0)');
  //     }
  //   });
  // };

  // var pcspMessageStart = function () { //アコーディオンの状態の初期化
  //   let resizecheck = $('#responsibleCheck').width();
  //   let resizecheck2 = 0;

  //   if (resizecheck == '2' && resizecheck != resizecheck2) { //SPの時のみ
  //     spMessageFunc();

  //     resizecheck2 = 2;
  //   } else if (resizecheck == '1' && resizecheck != resizecheck2) {
  //     pcMessageFunc();

  //     resizecheck2 = 1;
  //   }
  // }
  // pcspMessageStart();
  // $(window).resize(pcspMessageStart);
}

export function scrollMovieFunc() {

  $(".scrollMovie").each(function () {
    var windowScrollTop = $(window).scrollTop();
    var windowInnerHeight = window.innerHeight;

    var $video = $(this).find('video');
    var videoTop = $(this).offset().top;
    var videoHeight = $(this).innerHeight();

    // videoが停止している、かつvideoが画面内に入ってきた場合、再生処理
    if ($video[0].paused && (windowScrollTop + windowInnerHeight > videoTop)) {
      $video[0].play();
    }
    // videoが再生中、かつ画面外に出た場合、停止処理
    if (!$video[0].paused && ((windowScrollTop + windowInnerHeight < videoTop) || (windowScrollTop > videoTop + videoHeight))) {
      $video[0].pause();
    }
  });

}